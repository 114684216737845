import { getCookie } from './queryHelpers'

type FetchHelper = {
  url: string
  method: string
  data?: object
  headers?: object
  signal?: AbortController['signal']
  contentCall?: string
  mode?: RequestInit['mode']
  credentials?: RequestInit['credentials']
}

const IGNORE_PATHS = ['/', '/logout', '/forgot-password', '/verify-number', '/reset-password']

export default async function fetchHelper(props?: FetchHelper, callback?: any) {
  if (IGNORE_PATHS.includes(window.location.pathname)) return callback ? callback() : props && caller(props)
  return fetch(`${window.location.origin}/api/auth/verify`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${getCookie('vl-accessToken')}`,
      xApiKey: `${getCookie('managementXApiKey')}`,
    },
  }).then((res) => {
    if ([401, 403].includes(res.status)) return (window.location.href = `${window.location.origin}/logout`)

    return callback ? callback() : props && caller(props)
  })
}

async function caller({ url, method, data, headers = {}, signal, contentCall, mode, credentials }: FetchHelper) {
  try {
    const res = await fetch(url, {
      method: `${method}`,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify(data),
      signal: signal ?? AbortSignal.timeout(500000),
      mode,
      credentials,
    })
    if (!res.headers.get('content-type')?.includes('application/json')) throw new Error('Invalid data')
    if (!res.ok) {
      const e = await res.json()
      if (!e) throw new Error('Invalid data')
      return e
    }
    if (res.status === 403) return window.location.replace(window.location.host)
    const responseData = await res.json()
    if (responseData.status) {
      responseData['contentStatus'] = responseData.status
    }
    responseData['status'] = res.status
    if (contentCall) {
      responseData['contentCall'] = contentCall
    }
    return responseData
  } catch (error) {
    if (signal?.aborted) return console.error('Request cancelled')
    return error as Error
  }
}
