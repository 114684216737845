import { useCallback } from 'react'
import { useCookies } from 'react-cookie'
// import Tabs from '../json/tabs.json'

export type ApplicationAccessLevelType = 'read' | 'write' | 'contributor' | 'false' | false
export type UserRoleType = { [key in ApplicationSegmentType]: ApplicationAccessLevelType }
export type ApplicationSegmentType =
  | 'Admin'
  | 'Dashboard 360'
  | 'Subscriptions & Offers'
  | 'Content'
  | 'Customer Support' // this is users page
  | 'App CMS'
  | 'APIs'
  | 'Intelligence'

export default function useRbac() {
  const [cookies] = useCookies()

  const shouldAllowToContinue = useCallback((pageName: ApplicationSegmentType) => {
    const localRoleMap = localStorage.getItem("user-roles") ? JSON.parse(localStorage.getItem("user-roles") as string) : undefined
    const roles = cookies?.userRoles ?? localRoleMap as UserRoleType | undefined
    if (!roles) return false
    if (roles[pageName] === 'write' || roles[pageName] === 'read' || roles[pageName] === 'contributor') return true
    return false
  }, [cookies?.userRoles])
  
  const shouldAllowEdit = useCallback((pageName: ApplicationSegmentType) => {
    const localRoleMap = localStorage.getItem("user-roles") ? JSON.parse(localStorage.getItem("user-roles") as string) : undefined
    const roles = cookies?.userRoles ?? localRoleMap as UserRoleType | undefined
    if (!roles) return false
    if (roles[pageName] === 'write' || roles[pageName] === 'contributor') return true
    return false
  }, [cookies?.userRoles])

  return {
    shouldAllowToContinue,
    shouldAllowEdit,
    getRedirectionUrl
  }
}

export function getRedirectionUrl(roles?: UserRoleType) {
  if (!roles) return "/"
  const pageMap: Record<string, string> = {
    'Dashboard 360': '/analytics',
    'Subscriptions & Offers': '/monetization/models',
    'Content': '/content',
    'Customer Support': '/users',
    'App CMS': '/appcms',
    'Admin': '/admin',
  }
  const allowed = Object.keys(pageMap).find((pageName) => {
    const p = pageName as ApplicationSegmentType
    if (roles[p] === 'write' || roles[p] === 'read' || roles[p] === 'contributor') {
      return pageMap[pageName]
    }
  })
  return allowed ? pageMap[allowed] : "/forbidden"
}