// * If a new provider is added, the handleSocialSignIn function should be updated to include the new provider query.

import { fetchData, generateMinifiedQuery } from 'src/helpers/graphQLHelper'
import { SocialLoginProvider } from 'src/types/SocialLoginType'

export const GoogleLoginQuery = `
mutation googleLogin($token: String!) {
    googleLogin(token: $token) {
        success
        v2ManagementApiEnabled
        v2VodManagementApiEnabled
        isTwoFactorOnLogin
        obscureMobileNumber
        username
        accessToken
        refreshToken
    }
}`
export const MicrosoftLoginQuery = `
mutation microsoftLogin($token: String!) {
    microsoftLogin(token: $token) {
        success
        v2ManagementApiEnabled
        v2VodManagementApiEnabled
        isTwoFactorOnLogin
        obscureMobileNumber
        username
        accessToken
        refreshToken
    }
}`

export async function handleSocialSignIn(oAuthToken: string, provider: SocialLoginProvider) {
  const query = provider === 'google' ? GoogleLoginQuery : MicrosoftLoginQuery
  const payload = {
    graphQLReq: {
      query: generateMinifiedQuery(query),
      variables: {
        token: oAuthToken,
      },
    },
  }
  const data = await fetchData(payload)
  if (data instanceof Error) return data
  if (provider === 'google') {
    return data.googleLogin
  }
  if (provider === 'microsoft') {
    return data.microsoftLogin
  }
}