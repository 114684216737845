import { useRouter } from 'next/router'
import tabsData from 'src/json/tabs.json'

const useMenus = () => {
  const router = useRouter()
  const tab = tabsData.find((item) => router.pathname.includes(item.route))
  const checkIfSelected = (tabItems = []) => {
    const selected = tabItems.find((item) => {
      return item.route === router.pathname
    })
    return Boolean(selected)
  }
  return {
    allTabs: tabsData,
    activeTab: tab,
    activeTabItem: {},
    checkIfSelected,
  }
}

export default useMenus
