import '../styles/global.css'

import { useState, useEffect } from 'react'
import Head from 'next/head'
import { NextRouter, Router, useRouter } from 'next/router'
import { AppProps } from 'next/app'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { CacheProvider, EmotionCache } from '@emotion/react'
import { Button, Stack } from '@mui/material'
import { ApolloProvider } from '@apollo/client'

import theme from '../src/theme/theme'
import createEmotionCache from '../src/createEmotionCache'
import MainHeader from '../src/components/MainHeader'
import { AppProvider } from 'src/contexts/AppContext'
import LoadingOverlay from 'src/components/common/LoadingOverlay'
import UserProvider from 'src/components/common/UserProvider'
import MonetizationTabs from 'src/json/monetizationTabs.config.json'
import useRbac from 'src/hooks/useRbac'
import { CookiesProvider } from 'react-cookie'
import ErrorWrapper from 'src/components/ErrorWrapper'
import useInactivityChecker from 'src/hooks/useInactivityChecker'
import initApolloClient from 'src/graphql/lib/apollo'

const clientSideEmotionCache = createEmotionCache()
interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
}

export default function MyApp(props: MyAppProps) {
  const router = useRouter()
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    Router.events.on('routeChangeStart', () => setLoading(true))
    Router.events.on('routeChangeComplete', () => setLoading(false))
    Router.events.on('routeChangeError', () => setLoading(false))
    return () => {
      Router.events.off('routeChangeStart', () => setLoading(true))
      Router.events.off('routeChangeComplete', () => setLoading(false))
      Router.events.off('routeChangeError', () => setLoading(false))
    }
  }, [])

  useInactivityChecker({ timeoutMinutes: process.env.NEXT_PUBLIC_INACTIVITY_TIMEOUT ? parseInt(process.env.NEXT_PUBLIC_INACTIVITY_TIMEOUT) : 120 })

  const apolloClient = initApolloClient()
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta name="theme-color" content="#1370B3" />
        <meta name="description" content="ViewLift - CMS Tools" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="icon" href="/favicon-16x16.png" type="image/png" />
        <link rel="icon" href="/favicon-32x32.png" type="image/png" />
        <link rel="icon" href="/android-chrome-192x192.png" type="image/png" />
        <link rel="icon" href="/android-chrome-512x512.png" type="image/png" />
        <meta property="og:image" content={'/cover.png'} />
        <meta property="og:title" content="ViewLift - CMS Tools" />
        <meta property="og:description" content="ViewLift - CMS Tools" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <title>ViewLift - CMS Tools</title>
      </Head>
      <ApolloProvider client={apolloClient}>
        <ErrorWrapper>
          <CookiesProvider>
            <UserProvider>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <MainHeader />
                {router.pathname.includes('/monetization') ? <MonetizationTabBar router={router} /> : false}
                <AppProvider>
                  {loading ? <LoadingOverlay /> : false}
                  <Component {...pageProps} />
                </AppProvider>
              </ThemeProvider>
            </UserProvider>
          </CookiesProvider>
        </ErrorWrapper>
      </ApolloProvider>
    </CacheProvider>
  )
}

function MonetizationTabBar({ router }: { router: NextRouter }) {
  const { shouldAllowToContinue } = useRbac()

  useEffect(() => {
    if (!shouldAllowToContinue('Subscriptions & Offers')) return
  }, [])

  return (
    <Stack direction="row" sx={{ boxShadow: 'inset 0 -1px #eee' }}>
      {MonetizationTabs.map(({ key, route, title }) => (
        <Button
          sx={{
            padding: '0.8rem 1rem',
            boxShadow: router.asPath.includes(route) ? `inset 0 -2px ${theme.palette.primary.main}` : 'none',
            borderRadius: 0,
          }}
          key={key}
          onClick={() => router.push(route)}
          id={key}
        >
          {title}
        </Button>
      ))}
    </Stack>
  )
}
