import { useCallback, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useRouter } from 'next/router'
import { jwtDecode } from 'jwt-decode'

import { FORGOT_PASSWORD, GENERATE_OTP, LOGIN, RESET_PASSWORD, USER_INFO, VERIFY_OTP } from 'src/constants/urlConstants'
import fetchHelper from 'src/helpers/fetchHelper'
import { getRedirectionUrl } from './useRbac'
import { onSocialLogin } from 'src/lib/firebase'
import { SocialLoginProvider } from 'src/types/SocialLoginType'
import { handleSocialSignIn } from 'src/graphql/auth/socialLogin'

interface Roles {
  [key: string]: string
}
interface UserData {
  roles: Roles
  gaId: string
  siteId: string
  siteMap: string
  desktopLogo: string
  isServiceLaunched: boolean
  isToolsMonetizationModelEnabled: boolean
  site: string
  username: string
  otpDetails: string
  siteType: string
  firebasePropertyId: string
  serviceId: string
  domainName: string
  enableSSAISupport: boolean
  bucket: string
  analyticsURL: string
  reelybaseURL: string
  encodingService: string
  contributorData: string
  contentContributorData: string
  renditionUrl: string
  templateBuilderURL: string
  tbHostUrl: string
  isDRMEncryptionRequired: string
  isVerified: string
  settingsId: string
  enableQOS: string
  enableLiveStream: string
  bucketRegion: string
  isSocialPublishAutomated: string
  managementXApiKey: string
  isVerticleVideoEnabled: boolean
}

const getExpiryTime = (_token_: string) => {
  if (!_token_) return undefined
  const _d = jwtDecode(_token_).exp
  if (!_d) return new Date(new Date().getTime() + 12 * 60 * 60000)
  const exp = new Date(_d * 1000)
  return exp
}

const useLogin = () => {
  const [message, setMessage] = useState('')
  const [success, setSuccess] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies()
  const [loading, setLoading] = useState(false)
  const router = useRouter()
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')

  // const domain = getDomain()
  const domain = typeof window !== 'undefined' ? window.location.hostname : ''

  const forgot = router?.query.forgot

  const loginWithSocial = async (provider: SocialLoginProvider) => {
    // get token from provider
    setLoading(true)
    const _res = await onSocialLogin(provider)
    if (_res instanceof Error) {
      setLoading(false)
      return setMessage(_res.message)
    }
    // get access token for selected provider
    const _data = await handleSocialSignIn(_res, provider)
    if (_data instanceof Error) {
      setLoading(false)
      return setMessage(_data.message)
    }
    const { success, isTwoFactorOnLogin, message, accessToken, v2ManagementApiEnabled, username, obscureMobileNumber } = _data
    if (!success) {
      setLoading(false)
      return setMessage(message)
    }

    setCookie('v2ManagementApiEnabled', v2ManagementApiEnabled, commonCookieAttrs)
    setCookie('obscureMobileNumber', obscureMobileNumber, commonCookieAttrs)

    commonCookieAttrs.expires = getExpiryTime(accessToken)
    // handle 2fa
    if (isTwoFactorOnLogin) {
      setCookie('username', username, {
        ...commonCookieAttrs,
      })
      router.push('/verify-number')
      return
    }
    setCookie('vl-accessToken', accessToken, {
      ...commonCookieAttrs,
    })
    // get user info
    const res = await getUserInfo(accessToken)
    const roles = onSuccessLogin(res)
    const redirectionUrl = getRedirectionUrl(roles as any)
    setLoading(false)
    router.push(redirectionUrl)
    return
  }

  const getUserInfo = async (token: string) => {
    const data = await fetchHelper({
      url: USER_INFO,
      method: 'GET',
      headers: {
        authorization: 'Bearer ' + token,
      },
    })
    return data.response
  }

  const commonCookieAttrs: {
    path: string
    domain?: string
    expires: Date | undefined
  } = {
    path: '/',
    domain,
    expires: undefined,
  }

  function generateTbCookie(tbHostUrl: string, site: string, managementXApiKey: string) {
    // generate tb cookie after login or if it does not exist in cookies
    const tbCookieName = `${isolateTbUrl(tbHostUrl)}-${site}-key`
    const tbCookie = cookies && cookies[tbCookieName]
    if (!tbCookie)
      return setCookie(`${isolateTbUrl(tbHostUrl)}-${site}-key`, managementXApiKey, {
        ...commonCookieAttrs,
        domain: getTopLevelDomain(window.location.hostname),
      })
    // if tbToken already exists in cookies, check if tb token is expired
    const tbTokenExpiry = getExpiryTime(cookies['vl-accessToken'])
    if (tbTokenExpiry && tbTokenExpiry < new Date())
      return setCookie(`${isolateTbUrl(tbHostUrl)}-${site}-key`, managementXApiKey, {
        ...commonCookieAttrs,
        domain: getTopLevelDomain(window.location.hostname),
      })
  }

  const handleLogin = async (data: object) => {
    setLoading(true)
    const { success, isTwoFactorOnLogin, message, accessToken, v2ManagementApiEnabled, username, obscureMobileNumber } = await fetchHelper({
      url: LOGIN,
      method: 'POST',
      data,
    })
    setLoading(false)

    if (!success) {
      setMessage(message)
      return
    }

    setCookie('v2ManagementApiEnabled', v2ManagementApiEnabled, commonCookieAttrs)
    setCookie('obscureMobileNumber', obscureMobileNumber, commonCookieAttrs)

    commonCookieAttrs.expires = getExpiryTime(accessToken)

    if (isTwoFactorOnLogin) {
      setCookie('username', username, {
        ...commonCookieAttrs,
      })
      router.push('/verify-number')

      return
    }

    setCookie('vl-accessToken', accessToken, {
      // expires: getExpiryTime(accessToken),
      ...commonCookieAttrs,
    })
    const res = await getUserInfo(accessToken)
    const roles = onSuccessLogin(res)
    const redirectionUrl = getRedirectionUrl(roles as any)
    router.push(redirectionUrl)
  }

  const generateOtp = async () => {
    const { success, message } = await fetchHelper({
      url: GENERATE_OTP,
      method: 'POST',
      data: {
        username: cookies.username,
        isLogin: true,
      },
    })

    setSuccess(success)
    setMessage(message)

    if (!success) return

    setLoading(false)
  }

  const verifyOtp = async ({ otp }: { otp: string }) => {
    setLoading(true)

    const re = await fetchHelper({
      url: VERIFY_OTP,
      method: 'POST',
      data: {
        username: cookies.username,
        otp,
        isLogin: !forgot,
      },
    })
    const { success, message, accessToken, v2ManagementApiEnabled } = re
    setLoading(false)
    setMessage(message)
    setSuccess(success)
    if (!success) {
      return
    }
    setCookie('vl-accessToken', accessToken, {
      ...commonCookieAttrs,
      domain,
    })
    setCookie('v2ManagementApiEnabled', v2ManagementApiEnabled, commonCookieAttrs)
    if (forgot) {
      router.push(`/reset-password?token=${accessToken}`)
    } else {
      const res = await getUserInfo(accessToken)
      const roles = onSuccessLogin(res)
      const redirectionUrl = getRedirectionUrl(roles as any)
      router.push(redirectionUrl)
    }
  }

  const forgotPassword = async (data: { username: string }) => {
    setLoading(true)
    const { success, message, errorMessage } = await fetchHelper({
      url: FORGOT_PASSWORD,
      method: 'POST',
      data,
    })

    setLoading(false)
    setSuccess(success)
    setMessage(message || errorMessage)

    if (!success) return
    setCookie('username', data.username, {
      ...commonCookieAttrs,
    })

    router.push('/verify-number?forgot=true')
  }

  const resetPassword = async (data: { newPassword: string }) => {
    const token = router?.query?.token
    setLoading(true)
    const { success, message } = await fetchHelper({
      url: RESET_PASSWORD,
      method: 'POST',
      data: { ...data, token },
      headers: {
        Authorization: cookies['vl-accessToken'],
        xApiKey: cookies.managementXApiKey,
      },
    })

    setLoading(false)
    setSuccess(success)
    setMessage(message)

    if (!success) return
    removeCookie('vl-accessToken', { path: '/', domain })
    router.push('/')
  }

  const handlePassword = () => {
    setLoading(true)
    if (newPassword !== confirmPassword) {
      setLoading(false)
      setMessage('New Password and Confirm Password must match.')
      return
    }
    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams.get('token')
    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/
    if (pattern.test(confirmPassword)) {
      const data = {
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      }
      fetchHelper({
        url: `${process.env.NEXT_PUBLIC_V3_API_URL}/user/auth/reset-password`,
        method: 'POST',
        data: data,
        headers: {
          Authorization: token,
        },
      }).then(function (result) {
        if (result instanceof Error) {
          setMessage('Password reset failed!')
        } else {
          removeCookie('vl-accessToken', { path: '/', domain })
          router.push('/')
        }
      })
    } else {
      setMessage('Password should be of minimum 8 characters, must include 1 special character, 1 lowercase and uppercase character, and 1 number.')
      setLoading(false)
      return
    }
  }

  const getIsLoggedIn = () => {
    return Boolean(cookies['vl-accessToken'])
  }

  function deleteAllCookies() {
    const script = document.createElement('script')
    const domainName = cookies.DomainName
    script.src = `https://${domainName[0]}/thirdparty?logout=true`
    document.body.appendChild(script)

    const Cookies = document.cookie.split('; ')
    for (let c = 0; c < Cookies.length; c++) {
      const d = window.location.hostname.split('.')
      while (d.length > 0) {
        const cookieBase =
          encodeURIComponent(Cookies[c].split(';')[0].split('=')[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path='
        const p = location.pathname.split('/')
        document.cookie = cookieBase + '/'
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/')
          p.pop()
        }
        d.shift()
      }
    }
  }

  const handleLogout = useCallback(
    (destination?: string) => {
      deleteAllCookies()
      router.replace(destination ? destination : '/')
    },
    [router]
  )

  const onSuccessLogin = (data: UserData) => {
    const accessList = data.roles
    // localStorage['user-roles'] = JSON.stringify(data.roles)
    setCookie('userRoles', JSON.stringify(data.roles), {
      ...commonCookieAttrs,
    })
    const targetOrigin = '*'
    postMessage({ LoggedIn: true }, targetOrigin)
    setCookie('adminAccess', accessList['Admin'] === 'read', commonCookieAttrs)
    const script = document.createElement('script')
    script.src = `https://${data.domainName[0]}/thirdparty?login=true`
    document.body.appendChild(script)
    setCookie('contentAccess', accessList['Content'] === 'read', commonCookieAttrs)
    setCookie('subOfferAccess', accessList['Subscriptions & Offers'] === 'read', commonCookieAttrs)
    setCookie('custAccess', accessList['Customer Support'] === 'read', commonCookieAttrs)
    localStorage['userSiteMap'] = data.siteMap ? JSON.stringify(data.siteMap) : []

    localStorage['isServiceLaunched'] = data.isServiceLaunched
    setCookie('gaId', data.gaId, {
      ...commonCookieAttrs,
    })
    setCookie('siteId', data.siteId, commonCookieAttrs)
    if (location.hostname == 'localhost') {
      setCookie('isUserAdmin', true, { domain: 'localhost' })
    } else {
      setCookie('isUserAdmin', true, { domain: data.domainName[0].replace('www', ''), sameSite: 'none', secure: true })
    }
    setCookie('desktopLogo', data.desktopLogo, commonCookieAttrs)
    setCookie('site', data.site, {
      ...commonCookieAttrs,
    })
    setCookie('user', data.username, {
      ...commonCookieAttrs,
    })
    setCookie('otpDetails', data.otpDetails, {
      ...commonCookieAttrs,
    })
    setCookie('siteType', data.siteType, {
      ...commonCookieAttrs,
    })
    setCookie('firebasePropertyId', data.firebasePropertyId, {
      ...commonCookieAttrs,
    })
    setCookie('serviceId', data.serviceId, {
      ...commonCookieAttrs,
    })
    setCookie('DomainName', data.domainName, {
      ...commonCookieAttrs,
    })
    setCookie('bucket-name', data.bucket, {
      ...commonCookieAttrs,
    })
    setCookie('analyticsURL', data.analyticsURL, {
      ...commonCookieAttrs,
    })
    setCookie('reelybaseURL', data.reelybaseURL, {
      ...commonCookieAttrs,
    })
    setCookie('encodingService', data.encodingService, {
      ...commonCookieAttrs,
    })
    setCookie('contributorData', data.contributorData, {
      ...commonCookieAttrs,
    })
    setCookie('contentContributorData', data.contentContributorData, {
      ...commonCookieAttrs,
    })
    setCookie('isToolsMonetizationModelEnabled', data.isToolsMonetizationModelEnabled, {
      ...commonCookieAttrs,
    })
    setCookie('renditionUrl', data.renditionUrl, {
      ...commonCookieAttrs,
    })
    setCookie('templateBuilderURL', data.templateBuilderURL, {
      ...commonCookieAttrs,
    })
    setCookie(`tbHostUrl`, data.tbHostUrl, {
      ...commonCookieAttrs,
    })
    generateTbCookie(data.tbHostUrl, data.site, data.managementXApiKey)
    // setCookie(`${isolateTbUrl(data.tbHostUrl)}-${data.site}-key`, data.managementXApiKey, {
    //   ...commonCookieAttrs,
    //   domain: getTopLevelDomain(window.location.hostname),
    // })
    setCookie('tbUserToken', data.username, {
      ...commonCookieAttrs,
      domain: getTopLevelDomain(window.location.hostname),
    })
    setCookie('clientLogo', data.desktopLogo, {
      ...commonCookieAttrs,
      domain: getTopLevelDomain(window.location.hostname),
    })
    setCookie('isDRMEncryptionRequired', data.isDRMEncryptionRequired, {
      ...commonCookieAttrs,
    })
    setCookie('isVerified', data.isVerified, {
      ...commonCookieAttrs,
    })
    setCookie('settingsId', data.settingsId, {
      ...commonCookieAttrs,
    })
    setCookie('enableQOS', data.enableQOS, {
      ...commonCookieAttrs,
    })
    setCookie('enableLiveStream', data.enableLiveStream, {
      ...commonCookieAttrs,
    })
    setCookie('enableSSAISupport', data.enableSSAISupport, {
      ...commonCookieAttrs,
    })
    setCookie('bucketRegion', data.bucketRegion, {
      ...commonCookieAttrs,
    })
    setCookie('managementXApiKey', data.managementXApiKey, {
      ...commonCookieAttrs,
    })
    setCookie('isVerticleVideoEnabled', data.isVerticleVideoEnabled, {
      ...commonCookieAttrs,
    })

    if (data.site == 'rchdtv') {
      setCookie('enableEncoding', false, {
        ...commonCookieAttrs,
      })
    } else {
      setCookie('enableEncoding', true, {
        ...commonCookieAttrs,
      })
    }

    localStorage['content-view.current-siteid'] = '/' + data.site
    localStorage['isSocialPublishAutomated'] = data.isSocialPublishAutomated

    return data.roles
  }

  return {
    handleLogin,
    verifyOtp,
    generateOtp,
    forgotPassword,
    loading,
    message,
    setMessage,
    resetPassword,
    success,
    setSuccess,
    getIsLoggedIn,
    handleLogout,
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    handlePassword,
    generateTbCookie,
    deleteAllCookies,
    loginWithSocial
  }
}

export default useLogin

export function getTopLevelDomain(hostname: string) {
  const parts = hostname.split('.')
  if (parts.length === 1) return parts[0]
  if (parts.length === 2) return parts.join('.')
  return parts.slice(parts.length - 2).join('.')
}
function isolateTbUrl(tbUrl: string): string {
  return tbUrl.split('://')[1]
}
