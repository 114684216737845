export type SocialLoginProvider = 'google' | 'microsoft'

export type SocialLoginButtonConfig = {
  id: SocialLoginProvider
  label: string
  icon?: JSX.Element
  enable: boolean
}

// https://firebase.google.com/docs/reference/js/auth#autherrorcodes
export const FirebaseErrorCodeMap = {
  'auth/invalid-email': 'Invalid email address format.',
  'auth/wrong-password': 'Incorrect password.',
  'auth/user-not-found': 'No user exists with the provided credentials.',
  'auth/email-already-in-use': 'Email address is already associated with an account.',
  'auth/weak-password': 'Password is too weak.',
  'auth/credential-already-in-use': 'Credential is already used by another user.',
  'auth/requires-recent-login': 'Recent login required for sensitive operations.',
  'auth/user-disabled': 'User account has been disabled by an administrator.',
  'auth/too-many-requests': 'Requests are being made too quickly.',
  'auth/network-request-failed': 'Network issue or connection failure.',
  'auth/account-exists-with-different-credential': 'Email is already in use with a different credential.',
  'auth/operation-not-allowed': 'Certain operation is not allowed, like email/password sign-in.',
  'auth/cancelled-popup-request': 'User closed the popup before completing authentication.',
  'auth/popup-closed-by-user': 'User closed the popup before completing authentication.',
  'auth/timeout': 'Operation timed out.',
  'auth/invalid-verification-code': 'Invalid verification code used for 2FA or phone verification.',
  'auth/user-token-expired': "User's ID token has expired.",
  'auth/quota-exceeded': 'Quota for the operation exceeded.',
  'auth/invalid-app-credential': 'Invalid app credential.',
  'auth/invalid-tenant-id': 'Invalid tenant ID.',
}