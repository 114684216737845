import { ErrorOutline, Replay } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import Link from 'next/link'
import React, { ErrorInfo, ReactNode } from 'react'
import theme from 'src/theme/theme'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorWrapper extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error): State {
    console.error(error)
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error('Uncaught error:', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
        return (
          <Stack bgcolor={'rgba(12, 81, 119, 0.04)'} height={'100vh'} alignItems={'center'} justifyContent={'center'} gap={6}>
            <Stack alignItems={'center'} justifyContent={'center'} gap={0.5}>
              <ErrorOutline sx={{ fontSize: '5rem' }} color="error" />
              <Typography variant="h5" fontWeight={600} color="error">
                Something went wrong
              </Typography>
              {/* {this.state.errorMessage ? <Typography>{this.state.errorMessage}</Typography> : false} */}
              <Button onClick={() => window.location.reload()} sx={{ color: theme.palette.primary.main, marginTop: '1.5rem' }} startIcon={<Replay />}>
                Reload Page
              </Button>
            </Stack>
            <Typography>
              If the problem persists, please contact{' '}
              <Link style={{ color: theme.palette.primary.main, fontWeight: 600 }} href="mailto:techsupport@viewlift.com">
                techsupport@viewlift.com
              </Link>
            </Typography>
          </Stack>
        )
    }

    return this.props.children
  }
}

export default ErrorWrapper


