import { Button, ButtonProps } from '@mui/material'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export type DialogBoxPropType = DialogProps & {
  headerText?: string
  bodyText: string | JSX.Element | null
  positiveAction: { label: string;  } & Partial<ButtonProps>
  negativeAction: { label: string;  } & Partial<ButtonProps>
}

export default function DialogBox({ open, onClose, headerText, positiveAction, negativeAction, bodyText, sx,  ...rest }: DialogBoxPropType) {
    
  return (
    <Dialog open={open} onClose={onClose} sx={{maxWidth: "30rem", margin: "0 auto", ...sx}} fullWidth {...rest}>
      {headerText ? <DialogTitle>{headerText} </DialogTitle> : false}
      {bodyText ? <DialogContent>
        {typeof bodyText === "string" ? <DialogContentText>{bodyText}</DialogContentText> : bodyText}
      </DialogContent> : false}
      <DialogActions sx={{display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 2, marginTop: "2rem",}} >
        { negativeAction.label !== '' ? <Button color="error" variant='text' onClick={negativeAction.onClick} {...negativeAction}>{negativeAction.label}</Button> : <></>}
        { positiveAction.label !== '' ? <Button variant='contained' onClick={positiveAction.onClick} {...positiveAction}>{positiveAction.label}</Button> : <></>}
      </DialogActions>
    </Dialog>
  )
}