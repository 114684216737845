import { Box, CircularProgress } from '@mui/material'

export default function LoadingOverlay() {
  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      right={0}
      bottom={0}
      width={'100%'}
      display="flex"
      alignItems="center"
      justifyContent="center"
      zIndex={99999}
      sx={{
        background: 'rgba(0,0,0,0.2)',
        backdropFilter: 'blur(2px)',
        WebkitBackdropFilter: 'blur(2px)',
        pointerEvents: 'none',
        scrollBehavior: 'none',
      }}
    >
      <CircularProgress />
    </Box>
  )
}

export function InlineLoader() {
  return <CircularProgress size={20} />
}