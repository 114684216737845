import axios from 'axios'

const endpoint = `${process.env.NEXT_PUBLIC_V1_API_URL}management/graphql`

export const generateMinifiedQuery = (query) => {
  return query?.replace(/\s+/g, ' ')?.trim()
}

export async function fetchData(data) {
  try {
    const res = await axios({
      url: endpoint,
      method: 'POST',
      data: data.graphQLReq,
      headers: data.headers,
    })
    if (res) {
      if (res?.data?.errors?.length) {
        const err = res?.data?.errors[0]
        const error = new Error(err?.message ?? 'Something went wrong!')
        throw error
      } else {
        const data = res?.data?.data
        return data
      }
    }
  } catch (err) {
    return err
  }
}