import { useState, useEffect } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import Image from 'next/image'
import logo from 'public/viewlift/unofficial-viewlift-logo.png'
import useTabs from 'src/hooks/useTabs'
import useLogin from 'src/hooks/useLogin'
import { useCookies } from 'react-cookie'
import { useRouter } from 'next/router'
import NextLink from 'next/link'
import { Link as MUILink } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import VerifyOTP from './VerifyOTP'
import fetchHelper from 'src/helpers/fetchHelper'
import useRbac from 'src/hooks/useRbac'
import DialogBox from './common/DialogBox'
import { getCookie } from 'src/helpers/queryHelpers'

const IgnorePaths = ['/', '/reset-password', '/forgot-password', '/content/[contentType]/[id]', '/logout', '/verify-number', '/forbidden', '/logout']

const AppBarCommonProps = {
  position: 'static',
  elevation: 0,
  sx: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    boxShadow: 'inset 0 -1px #eee',
  },
}
function ResponsiveAppBar() {
  const { shouldAllowToContinue, getRedirectionUrl } = useRbac()
  const router = useRouter()
  const [cookies] = useCookies()
  const { allTabs } = useTabs()
  const { deleteAllCookies } = useLogin()
  const [anchorElNav, setAnchorElNav] = useState(null)
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [initLogout, setInitLogout] = useState(false)
  const [isAuth, setIsAuth] = useState(false)
  const [desktopLogo, setDesktopLogo] = useState(null)
  const [verifyOtp, setVerifyOtp] = useState(false)

  useEffect(() => {
    setDesktopLogo(cookies.desktopLogo)
  }, [cookies, router])

  useEffect(() => {
    if (typeof window == 'undefined') return null
    const token = getCookie('vl-accessToken')
    if (token) setIsAuth(true)
  }, [router.pathname])

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const generateOTP = async () => {
    let data = {
      isLogin: true,
      username: cookies.user,
    }
    try {
      await fetchHelper({
        url: `${process.env.NEXT_PUBLIC_V3_API_URL}/user/auth/generate/otp`,
        method: 'POST',
        data: data,
        headers: {
          xApiKey: cookies.managementXApiKey,
          Authorization: cookies['vl-accessToken'],
        },
      })
    } catch {}
    setVerifyOtp(true)
  }

  const getHomePathForUser = () => {
    return router.push(getRedirectionUrl(cookies?.userRoles))
  }

  if (IgnorePaths.includes(router.pathname)) return null

  function LogoComponent() {
    return (
      <Box
        sx={{
          paddingX: 1,
          backgroundColor: 'primary.light',
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Image src={logo} width={65} alt="Logo" style={{ cursor: 'pointer' }} onClick={getHomePathForUser} />
      </Box>
    )
  }
  return (
    <>
      <AppBar {...AppBarCommonProps}>
        <LogoComponent />
        {!isAuth ? null : (
          <Toolbar
            disableGutters
            sx={{
              color: 'primary.main',
              display: 'flex',
              flex: 1,
              pl: 2,
              justifyContent: 'flex-end',
            }}
          >
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none', lg: 'none' },
                }}
              >
                {allTabs.map(({ key, title, appSegment, route }) =>
                  shouldAllowToContinue(appSegment) ? (
                    <MenuItem key={key} onClick={handleCloseNavMenu}>
                      <NextLink key={key} href={route} passHref style={{ textDecoration: 'none' }} legacyBehavior>
                        <MUILink
                          variant="body2"
                          color={router.pathname === route ? 'primary' : 'secondary'}
                          sx={{
                            textDecoration: router.pathname === route ? 'underline' : 'none',
                          }}
                        >
                          <Typography variant="subtitle1">{title}</Typography>
                        </MUILink>
                      </NextLink>
                    </MenuItem>
                  ) : (
                    false
                  )
                )}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', gridGap: 20 } }}>
              {allTabs.map(({ key, title, appSegment, route }) =>
                shouldAllowToContinue(appSegment) ? (
                  <NextLink key={key} href={route} passHref style={{ textDecoration: 'none' }} legacyBehavior>
                    <MUILink
                      variant="body2"
                      color={router.pathname === route ? 'primary' : 'secondary'}
                      sx={{
                        textDecoration: router.pathname === route ? 'underline' : 'none',
                      }}
                    >
                      <Typography variant="subtitle1">{title}</Typography>
                    </MUILink>
                  </NextLink>
                ) : (
                  false
                )
              )}
            </Box>
            <Box
              sx={{
                flexGrow: 0,
                height: '65px',
                display: 'flex',
                justifyContent: 'center',
                paddingLeft: desktopLogo?.length ? '1rem' : 0,
                paddingRight: '0.5rem',
                background: 'black',
              }}
            >
              <Tooltip>
                <IconButton
                  onClick={handleOpenUserMenu}
                  sx={{
                    p: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  TouchRippleProps={{ style: { display: 'none' } }}
                >
                  {desktopLogo?.length && (
                    <Image src={desktopLogo} width={85} height={45} style={{ width: '100px', height: '100%', objectFit: 'contain' }} alt="Logo" />
                  )}
                  <ArrowDropDownIcon color="info" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {shouldAllowToContinue('Admin') ? (
                  <MenuItem onClick={() => router.push('/admin')}>
                    <Typography textAlign="center">Admin</Typography>
                  </MenuItem>
                ) : (
                  false
                )}
                <MenuItem onClick={() => generateOTP()}>
                  <Typography textAlign="center">Change Password</Typography>
                </MenuItem>
                <MenuItem onClick={() => setInitLogout(true)}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        )}
        {verifyOtp && <VerifyOTP open={verifyOtp} handleClose={setVerifyOtp} />}
      </AppBar>
      {initLogout ? (
        <DialogBox
          open={initLogout}
          onClose={() => setShowDialog(false)}
          headerText="Are you sure you want to logout?"
          positiveAction={{
            label: 'Cancel',
            onClick: () => setInitLogout(false),
          }}
          negativeAction={{
            label: 'Yes, Logout',
            onClick: () => {
              deleteAllCookies()
              router.replace('/')
            },
          }}
        />
      ) : (
        false
      )}
    </>
  )
}
export default ResponsiveAppBar
