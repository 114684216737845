import { Auth, getAuth, GoogleAuthProvider, OAuthProvider, signInWithPopup } from '@firebase/auth'
import { FirebaseApp, FirebaseError, initializeApp } from 'firebase/app'

import { FirebaseErrorCodeMap, SocialLoginProvider } from 'src/types/SocialLoginType'

let firebaseApp: FirebaseApp | null
let firebaseAuth: Auth | null

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_APIKEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTHDOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECTID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APPID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENTID,
}

function initFirebase() {
  // Prevent initializing Firebase more than once on the client
  if (typeof window === "undefined" || !firebaseConfig.apiKey || firebaseApp) return
  firebaseApp = initializeApp(firebaseConfig)
}
function initAuth() {
  // prevent initializing Firebase Auth more than once on the client
  if (!firebaseApp || firebaseAuth) return
  firebaseAuth = getAuth(firebaseApp)
}

async function getProvider(providerName: SocialLoginProvider) {
  if (!firebaseConfig.apiKey) throw new FirebaseError("auth/invalid-api-key", "Missing or invalid API key.")
  let provider
  switch (providerName) {
    case 'google':
      provider = new GoogleAuthProvider()
      provider.setCustomParameters({
        prompt: 'select_account',
      })
      break
    case 'microsoft':
      const tenant = process.env.NEXT_PUBLIC_AZURE_TENANT_ID
      if (!tenant) throw new FirebaseError('auth/invalid-tenant-id', 'Missing or invalid tenant ID.')
      provider = new OAuthProvider('microsoft.com')
      provider.setCustomParameters({
        prompt: 'consent',
        tenant, // TODO: Add VL Azure tenant ID in env vars.
      })
      break
  }
  return provider
}

export async function onSocialLogin(providerName: SocialLoginProvider) {
  initFirebase()
  initAuth()
  try {
    const provider = await getProvider(providerName)
    if (!provider) return
    if (!firebaseAuth) return
    const r = await signInWithPopup(firebaseAuth, provider)
    return (r as any)._tokenResponse.oauthIdToken
  } catch (err) {
    process.env.NODE_ENV !== 'production' && console.error(err)
    if (err instanceof FirebaseError) return new Error(FirebaseErrorCodeMap[err.code as keyof typeof FirebaseErrorCodeMap] || 'An error occurred')
    return err as Error
  }
}
