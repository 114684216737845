import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { Button, Snackbar, TextField, Typography } from '@mui/material'
import { useCookies } from 'react-cookie'
import { useRouter } from 'next/router'
import fetchHelper from 'src/helpers/fetchHelper'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 500,
  minHeight: 180,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: '25px',
  borderRadius: '5px',
  textAlign: 'center',
}

export default function VerifyOTP({ open, handleClose }) {
  const router = useRouter()
  const [cookies] = useCookies()
  const [OTP, setOTP] = useState(null)
  const [number, setNumber] = useState(null)
  const [showMessage, setShowMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [token, setToken] = useState('')

  useEffect(() => {
    if (open) {
      const phoneNumber = cookies.obscureMobileNumber
      setNumber(phoneNumber)
    }
    // eslint-disable-next-line
  }, [open])

  useEffect(() => {
    if (token) {
      router.push(`/forgot-password?token=${token}`)
    }
    // eslint-disable-next-line
  }, [token])

  const confirmOTP = async () => {
    let data = {
      isLogin: true,
      otp: OTP,
      username: cookies.user,
    }
    fetchHelper({
      url: `${process.env.NEXT_PUBLIC_V3_API_URL}/user/auth/otp/verify`,
      method: 'POST',
      data: data,
      headers: {
        xApiKey: cookies.managementXApiKey,
        Authorization: cookies['vl-accessToken'],
      },
    }).then(function (result) {
      if (result instanceof Error) {
        setMessage('OTP verification failed!')
        setShowMessage(true)
      } else {
        setToken(result?.accessToken)
      }
    })
  }

  const resendCode = () => {
    let data = {
      isLogin: true,
      username: cookies.user,
    }
    fetchHelper({
      url: `${process.env.NEXT_PUBLIC_V3_API_URL}/user/auth/generate/otp`,
      method: 'POST',
      data: data,
      headers: {
        xApiKey: cookies.managementXApiKey,
        Authorization: cookies['vl-accessToken'],
      },
    }).then(function (result) {
      if (result instanceof Error) {
        setMessage('OTP could not be sent!')
        setShowMessage(true)
      } else {
        setMessage('OTP sent successfully!')
        setShowMessage(true)
      }
    })
  }

  const handleMessageClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setShowMessage(false)
  }

  return (
    <div>
      <Modal open={open} onClose={() => handleClose(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="otp-modal-title" variant="h6" component="h2" sx={{ fontSize: '28px' }}>
            Verify OTP
          </Typography>
          <Typography id="otp-modal-title" component="h5" sx={{ fontSize: '14px', marginTop: '20px' }}>
            Enter the 6-digit code sent to your mobile phone:
          </Typography>
          <Typography id="otp-modal-title" component="label" sx={{ fontSize: '14px', marginTop: '15px', fontWeight: 500 }}>
            {`xxxxxx${number}`}
          </Typography>
          <TextField
            id="otp"
            label="OTP"
            value={OTP || ''}
            name="otp"
            onChange={(e) => setOTP(e.target.value)}
            variant="outlined"
            sx={{
              width: '100%',
              marginTop: '20px',
              '& .MuiInputBase-input': { height: '20px' },
            }}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '20px',
              marginTop: '30px',
            }}
          >
            <Button
              id="resendCode"
              variant="outlined"
              data-label="resendCode"
              onClick={resendCode}
              sx={{
                width: 150,
                height: 30,
                fontWeight: 500,
                fontSize: '14px',
                '&:hover': {
                  backgroundColor: 'primary.main',
                  color: '#FFFFFF',
                },
              }}
            >
              Resend Code
            </Button>
            <Button
              id="confirmOtp"
              variant="outlined"
              data-label="confirmOtp"
              onClick={confirmOTP}
              sx={{
                width: 150,
                height: 30,
                fontWeight: 500,
                fontSize: '14px',
                '&:hover': {
                  backgroundColor: 'primary.main',
                  color: '#FFFFFF',
                },
              }}
            >
              Confirm
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '30px',
            }}
          >
            <Typography id="otp-modal-title" component="h5" sx={{ fontSize: '14px' }}>
              Note: Messages and data rates may apply.
            </Typography>
            <Button disabled={true}>Wrong Number?</Button>
          </Box>
        </Box>
      </Modal>
      <Snackbar open={showMessage} autoHideDuration={5000} message={message} onClose={handleMessageClose} />
    </div>
  )
}
