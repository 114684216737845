import { getCookie } from 'src/helpers/queryHelpers'
import { memo, useCallback, useEffect, useMemo } from 'react'

import useLogin from 'src/hooks/useLogin'
import { useRouter } from 'next/router'

export default memo(function UserProvider({ children }) {
  const { deleteAllCookies } = useLogin()
  const { replace } = useRouter()
  const getMe = useCallback(async () => {
    try {
      const res = await fetch(`${window.location.origin}/api/auth/verify`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${getCookie('vl-accessToken')}`,
          xApiKey: `${getCookie('managementXApiKey')}`,
        },
      })
      const data = await res.json()
      if (!data.valid) {
        deleteAllCookies()
        replace('/logout')
      }
    } catch (error) {
      return null
    }
  }, [])

  const cloudProvider = useMemo(async () => {
    if (typeof window === 'undefined') return
    try {
      const res = await fetch(`${window.location.origin}/api/auth/verify/site`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${getCookie('vl-accessToken')}`,
          xApiKey: `${getCookie('managementXApiKey')}`,
        },
      })
      const data = await res.json()
      sessionStorage.setItem('cloudProvider', data.provider)
    } catch (error) {
      return null
    }
  }, [])

  useEffect(() => {
    getMe()
  }, [getMe])

  useEffect(() => {
    cloudProvider
  }, [cloudProvider])

  return <>{children}</>
})
