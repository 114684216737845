import { useCallback, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'

export type UseInactivityCheckerProps = {
  timeoutMinutes?: number
}

const IGNORE_PATHS = ['/', '/logout', '/reset-password', '/forgot-password', '/verify-number']

export default function useInactivityChecker({ timeoutMinutes = 30 }: UseInactivityCheckerProps) {
  const { pathname, replace } = useRouter()

  const timerRef = useRef<number | null>(null)

  const _timeout = timeoutMinutes * 60 * 1000

  const logout = useCallback(() => {
    const Cookies = document.cookie.split('; ')
    for (let c = 0; c < Cookies.length; c++) {
      const d = window.location.hostname.split('.')
      while (d.length > 0) {
        const cookieBase =
          encodeURIComponent(Cookies[c].split(';')[0].split('=')[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path='
        const p = location.pathname.split('/')
        document.cookie = cookieBase + '/'
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/')
          p.pop()
        }
        d.shift()
      }
    }
    replace('/logout')
  }, [replace])

  const resetTimer = useCallback(() => {
    if (IGNORE_PATHS.includes(pathname)) return
    if (timerRef.current !== null) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = window.setTimeout(logout, _timeout)
  }, [logout, _timeout, pathname])

  const handleActivity = useCallback(() => {
    resetTimer()
  }, [resetTimer])

  useEffect(() => {
    if (IGNORE_PATHS.includes(pathname)) return
    const events: (keyof WindowEventMap)[] = ['mousemove', 'mousedown', 'keypress', 'touchstart', 'click']
    events.forEach((event) => {
      window.addEventListener(event, handleActivity)
    })

    resetTimer()

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, handleActivity)
      })
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current)
      }
    }
  }, [handleActivity, logout, _timeout, resetTimer, pathname])

  return resetTimer
}
